header {
    background: #002224;
    background:
        linear-gradient(
            50deg,
            #7a9595e6 0%,
            #81819fe6 50%,
            #889ea2e6 100%
        );   
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
}

header .menu {
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
    align-content: center;
}

.logo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

}

.navigation{
    /* background-color: #fff; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(50px, auto);
    grid-auto-columns: 100px;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;   
}

.navigation li{
    width: 5rem;
    margin: 10px;
    padding-block: 5px;
    border-radius:5px;
    
    /* background-color: #5825; test */
    
    color: #5825d8;
    font-weight: 600;
    font-size: large;
    list-style-type: none;

    transition: 0.5s linear;

}


.navigation li:hover{
    color: #5825d8;
    background-color: #191ca155;
    border-radius:5px;
    font-weight: 900;
    border: #5825d8;
    cursor: pointer;
}

.navigation li a {
    text-decoration: none;
    text-align: center;
    display: block;
    color: #fff;
}
/* 
.centralButton{
    width: auto;
    height: auto;

    
    
}


.centralButton button{
    width: 5rem;
    height: 2rem;
    background-color: #191ca155;
    color: #fff;
    font-size: medium;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    transition: 0.5s;
    cursor: pointer;

    margin-right: 1rem;

}
.centralButton button a{
    color: #fff;
    list-style-type: none;
    text-decoration: none;
    
}

.centralButton button:hover{
    background-color: #44278c;

    border: none;
    border-radius: 5px;
} */