.container {
    padding-top: 84px;
    width: 100%;
    height: 100%;
    background: #222;
    color: #fff;
    text-align: center;



}

.img {
    width: 400px;
    max-width: 100%;
    height: auto;
    margin: 20px 0;
}
