.modalOverlay {
    background-color: #555;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Garante que o modal esteja acima de outros elementos */
  
}

.modal {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
}

.modalCloseBtn {
    background-color: #fe0d0d00;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s linear;
}

.modalCloseBtn:hover {
    background-color: #f71a1a9b;
}


.modalContent {
    max-width: 50vw;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalContent h2 {
    color: #000000da;
    padding-bottom: 1rem;
}

.modalContent p {
    color: #000000a0;
    padding-bottom: 1.5rem;
    text-align: center;
}

.modalContent span {
    color: #00000066;
}