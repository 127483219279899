.navFooter{
    background: #002224;
    background:
        linear-gradient(
            50deg,
            #7a9595e6 0%,
            #81819fe6 50%,
            #889ea2e6 100%
        );   
    color: #fff;
    width: auto;
    height: auto;
    padding: 1rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    grid-auto-rows: minmax(50px, auto);
    grid-auto-columns: 100px;
}

 .boxInfo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    
}
.boxInfo div{
    padding: 10px;
}

.boxInfo img {
    width: 100px;
}

.dingo {
    width: 100%;

    text-align: center;
    border-bottom: solid 1px #fff;
    box-shadow: 0px 1px 0px #c5c5c5;
}



.icon{
    width: 100px;
    color: #2a8ded !important;

}



/*AppSocial*/
.btn_social {

    width: auto;
    float: left;
    display: flex;
    margin: auto 0;
    
}

.btn_social ul {

    width: auto;
    /* margin:auto;  */
    float: left;
    display: block;
}

.btn_social ul li {

    padding: 10px;
    position: relative;
    background: #55555500;
    list-style: none;
    float: left;
    margin: 2px;
    z-index: 1;
    overflow: hidden;
    border-radius: 3px;
    font-size: 1.5em;
    text-align: center;

    
}

.btn_social ul li a {
    
    text-decoration: none;
    transition: .4s;
    
}

.btn_social ul li::before {

    transition: 0.4s;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 90%;
    left: 0;
    z-index: -1;
    transform: scale(1);
    transition: .4s ;
    background: #0174e7;
    
}

.btn_social ul li:hover::before {
    transition: 0.4s;
    background: linear-gradient(0deg, #000000 0%, #090979 10%, #0174e7 100%);
    top: 0%;
    
}

.btn_social ul li a:hover {
    color: #e3e3e3;
    
}












footer{
    width: 100%;
    height: 100%;
    background-color: #333;
    padding: 1rem;

    color: #fff;
    padding-block: 1rem;
    text-align: center;

    top: 0;
}






