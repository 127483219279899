// Cores
$primary-color: #3498db;
$secondary-color: #2ecc71;
$background-color: #222;
$text-color: #fff;

$background-card-color: #fff;
$text-primary-color: #ffffff;
$text-secondary-color: #000000;

// Fontes
$font-family: ' Arial , sans-serif';
$font-size: 16px;

// Tamanhos
$container-max-width: 1200px;
$header-height: 60px;
$footer-height: 40px;

// Sobras (Box Shadows)
$shadow-default: 0px 2px 4px #0000001a;
$shadow-hover: 0px 4px 8px #00000033;
$shadow-active: 0px 4px 9px #0000004d;
$box-shadow: 0 4px 6px #0000001a;

// Bordas (Border Radius)
$border-radius: 4px;

$transition: 0.3s ease;



