.scrollToTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #3498db;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;

    svg {
        width: 24px;
        height: 24px;
        fill: #fff;
    }

    &.visible {
        opacity: 1;
        visibility: visible;
    }

    &:hover {
        background-color: #2980b9;
    }
}
