@import '../../styles/_variables.scss';

.header{
    
        height: 10rem;
        background-color: #8bc1ff52;
        color: #fff ;
        font-family: $font-family;
        font-size: $font-size;

        padding: 1rem;
    
}

.card {
        width: 50rem;
        height: 20rem;
}

.modalContent {
        display: flex;
        flex-direction: column;
        align-items: center;
}

