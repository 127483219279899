.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #000000;
   
}

.loginForm {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px #0000001a;
    width: 100%;
    max-width: 400px;
}

.loginForm h2 {
    margin-bottom: 20px;
    color: #333;
}

.formGroup {
    margin-bottom: 15px;
}

.formGroup label {
    display: block;
    margin-bottom: 5px;
    color: #333;
}

.formGroup input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.loginButton {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.loginButton:hover {
    background-color: #0056b3;
}

.error {
    color: red;
    margin-bottom: 15px;
}
