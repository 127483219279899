/* src/components/Container.module.css */

.container {
    width: 100%; /* Largura total do container */
    height: 100vh; /* Altura total do container */

    margin: 0 auto; /* Centraliza o container na página */
    padding: 20px; /* Espaçamento interno do container */
    /* overflow-y: auto; Adiciona barra de rolagem vertical se o conteúdo interno for maior */
    
    background: #222; /* Cor de fundo do container */
    color: #fff; /* Cor do texto dentro do container */
    text-align: center; /* Alinhamento do texto centralizado */
  }
  